import React from 'react';
import useReactRouter from 'use-react-router';

import appLogo from '../../assets/logo-256.png';
import whiteAppLogo from '../../assets/logo-white.png';
import { Actions, useDispatch } from '../../store';
import { useStyles } from './styles';
/**
 * Displays the Outmind logo in either white or blue in either large or small size
 */
export const OutmindLogo: React.FC<OutmindLogoProps> = ({ clickGoHome, large, white }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { history } = useReactRouter();

  const goHome = (): void => {
    history.push('/');
    dispatch(Actions.setSearchQuery(''));
    dispatch(Actions.setSearchUserQuery(''));
    dispatch(Actions.resetSearch());
  };

  return clickGoHome ? (
    <div
      className={large ? classes.bigAppBanner : classes.appBanner}
      onClick={goHome}
      onKeyPress={goHome}
      role="button"
      tabIndex={0}
    >
      <img
        alt="app-logo"
        className={classes.appBannerLogoButton}
        src={white ? whiteAppLogo : appLogo}
      />
    </div>
  ) : (
    <div className={large ? classes.bigAppBanner : classes.appBanner}>
      <img alt="app-logo" className={classes.appBannerLogo} src={white ? whiteAppLogo : appLogo} />
    </div>
  );
};

interface OutmindLogoProps {
  clickGoHome?: boolean;
  large?: boolean;
  white?: boolean;
}
